/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "can-you-inherit-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-you-inherit-hearing-loss",
    "aria-label": "can you inherit hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can you inherit hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/dad-holding-daughter-hero.png",
    alt: "Dad holding daughter up in his arms",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Nowadays, genetics has become a very fashionable field, with genetic medicine becoming ever more promising, and millions of people around the world tracking their genetic past to find out their roots. But what if it was possible to look back in time, only ", React.createElement(_components.strong, null, "to discover that you possess genes for hearing loss?"), " Is it even possible to inherit poor hearing?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Well, yes and no. As with most physiological issues, ", React.createElement(_components.strong, null, "hearing loss has genetic and environmental components"), ". It’s not easy for specialists to tease out the connections between specific genes and real-world hearing loss, but they are starting to come up with some intriguing results. Let’s look in more detail about their findings and see whether it has any relevance for current hearing loss patients."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-common-is-inherited-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-common-is-inherited-hearing-loss",
    "aria-label": "how common is inherited hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How common is inherited hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When medical specialists look at inherited (or congenital) hearing loss, they tend to speak about hearing loss in new born babies. This kind of hearing loss is relatively easy to trace to genetic factors, and it’s surprisingly common. In fact, ", React.createElement(_components.strong, null, "around 0.3 percent of all babies born in the USA have some kind of congenital hearing issues"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This translates into a ", React.createElement(_components.strong, null, "1:333 chance of a baby being born with significant hearing impairment"), ", so it’s a major issue for parents, and it’s becoming much more common to test for it as the science of genetics develops."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As far as older hearing loss patients go, we can’t be sure what percentage of patients suffer from genetic deafness, and what proportion have been affected by environmental factors. What we do know, is that there are two ways that genetic factors play into hearing loss in adults."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "understanding-syndromic-and-non-syndromic-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-syndromic-and-non-syndromic-hearing-loss",
    "aria-label": "understanding syndromic and non syndromic hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding syndromic and non-syndromic hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first way to ", React.createElement(_components.strong, null, "classify hearing loss in adults is “non-syndromic” cases"), ". The name gives us a clue about what this entails. Non-syndromic cases involve hearing loss without any obvious cause from a separate “syndrome.”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Geneticists are particularly interested in non-syndromic cases, as they can often be traced back to common genetic factors. Without getting too technical, specialists divide such patients into autosomal dominant (or DFNA), autosomal recessive (DFNB), or X-linked (DFNX)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These classifications relate to precisely ", React.createElement(_components.strong, null, "which genes are triggering the hearing loss symptoms"), ". In theory, with more data about these triggers, geneticists will be able to formulate medicines to neutralize those genes. In the process, they may be able to arrest or slow down changes in the inner ear that are associated with non-syndromal hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The other type is ", React.createElement(_components.strong, null, "syndromal hearing loss"), ". In these cases, hearing is impacted by developments elsewhere in the body (such as environmental illnesses or other genetic conditions). ", React.createElement(_components.strong, null, "Hearing loss may still be heritable, but it is a second order condition"), " leading on from a different condition."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As far as we know, ", React.createElement(_components.strong, null, "around 30 percent of hearing loss in the USA arises from syndromic causes"), ", and 60 percent from non-syndromic causes. The consensus is that hereditary factors play a significant role in both, but not every hearing loss case can be blamed on genes."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-about-acquired-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-about-acquired-hearing-loss",
    "aria-label": "what about acquired hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What about acquired hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might also have come across instances where ", React.createElement(_components.strong, null, "children suffer from what specialists call “acquired hearing loss.”"), " In these cases, deafness is not thought to be genetically heritable."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In many instances, ", React.createElement(_components.strong, null, "children have inherited their condition via pre-natal bacteria or viruses"), " such as herpes or cytomegalovirus."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In America, the prevalence of this kind of hearing loss isn’t massive (it’s more pressing in developing nations where medical care isn’t as advanced). Nevertheless, around ", React.createElement(_components.strong, null, "0.6 percent of new borns are infected"), " with cytomegalovirus, which adds up to about 24,000 potential hearing loss cases."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, ", React.createElement(_components.strong, null, "acquired hearing loss is very common among American adults"), ". This is the kind of hearing loss that is caused mainly by exposure to loud noises at work, or listening to amplified music."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-find-out-if-your-hearing-loss-is-heritable",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-find-out-if-your-hearing-loss-is-heritable",
    "aria-label": "how to find out if your hearing loss is heritable permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to find out if your hearing loss is heritable"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unfortunately, it isn’t always easy to test for hereditary hearing loss before the symptoms have started to manifest themselves. However, there are some things to bear in mind when assessing your personal situation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most obviously, look at your family tree. ", React.createElement(_components.strong, null, "If your relatives suffer from endemic hearing loss, and there is no clear environmental cause"), ", you are probably at risk. There are exceptions (some families of construction workers have reported non-genetic epidemics of hearing loss, for example), but observing your family is always a good first step."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Secondly, ", React.createElement(_components.strong, null, "be aware of which syndromes can result in genetically inherited deafness"), ". Sometimes people are unaware that they suffer from these conditions, putting their hearing at risk, although most of the time they will be picked up during childhood."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most importantly, ", React.createElement(_components.strong, null, "consult expert advice if you are worried"), " about either suffering from hearing loss yourself, or passing it onto your children. Nowadays, many hospitals offer genetic tests for hearing loss risk factors, and these tests are becoming more accurate and informative all the time."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dont-assume-old-age-hearing-loss-is-heritable",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dont-assume-old-age-hearing-loss-is-heritable",
    "aria-label": "dont assume old age hearing loss is heritable permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Don't assume old age hearing loss is heritable"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, it’s important to note that people can panic unnecessarily about the probability of passing on hearing issues. As we noted earlier, scientists are very unsure what proportion of hearing loss in older people has a genetic component."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The likelihood is that ", React.createElement(_components.strong, null, "deafness in older people (and adults in general) has a very strong environmental element"), ". So, the way you look after your hearing, the situations you find yourself in, and the medical expertise you benefit from, will all feed into the chances of enjoying good hearing for life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Genetics is a developing field, and with hearing loss, there’s much we don’t know. So don’t worry too much, and if you are concerned, make a date with a specialist to discuss your anxieties."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
